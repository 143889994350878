@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
label {
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", */
  /*   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
  /*   sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  filter: drop-shadow(0px 11.3564px 17.0347px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 1.41955px 4.25866px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 0.709777px rgba(0, 0, 0, 0.04));
}

.custom-checkbox {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 18px;
  width: 18px;
  border: 1px solid var(--color-primary);
  /* background-color: #eee; */
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  width: 16px;
  height: 16px;
  background: var(--color-primary);

  top: 1px;
  left: 1px;
  width: 14px;
  height: 14px;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
